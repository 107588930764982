import styled from '@emotion/styled';
import { memo } from 'react';
import { ImSortAlphaAsc, ImSortAmountAsc, ImSortAmountDesc } from 'react-icons/im';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const stockScoreSortStore = proxy({
    sortType: 'default',
});
export const StockScoreSort = memo(function StockScoreSort() {
    const stkState = useSnapshot(stockScoreSortStore);
    return (<styleds.container>
      {stkState.sortType === 'default' && (<ImSortAlphaAsc onClick={() => (stockScoreSortStore.sortType = 'desc')}/>)}

      {stkState.sortType === 'desc' && (<ImSortAmountDesc onClick={() => (stockScoreSortStore.sortType = 'asc')}/>)}

      {stkState.sortType === 'asc' && (<ImSortAmountAsc onClick={() => (stockScoreSortStore.sortType = 'default')}/>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    color: #ffffff;
    font-size: 20px;
    gap: 4px;
    > * {
      padding: 4px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background-color: #454545;
      }
    }
  `,
};
