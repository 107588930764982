import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const stockRatioStore = proxy({
    up: 0,
    down: 0,
    total: 0,
});
export const StockRatioGraph = memo(function StockRatioGraph() {
    const state = useSnapshot(stockRatioStore);
    const upCount = state.down === 0 ? state.up + 1 : state.up;
    const dnCount = state.up === 0 ? state.down + 1 : state.down;
    const upWidth = (upCount / state.total) * 100;
    const downWidth = (dnCount / state.total) * 100;
    return (<div css={css `
        ${fill_horizontal_all_center};
        gap: 2px;
        font-size: 14px;
      `}>
      <styleds.rationContent width={upWidth < 8 ? 8 : upWidth} fill1='#cc272f' fill2='#fb5656'>
        {state.up}家
      </styleds.rationContent>

      <styleds.rationContent width={downWidth < 8 ? 8 : downWidth} fill1='#72c54a' fill2='#acff66bb'>
        {state.down}家
      </styleds.rationContent>
    </div>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 2px;
    font-size: 14px;
  `,
    rationContent: styled.div `
    ${fill_horizontal_all_center};
    width: ${props => props.width}%;
    background: linear-gradient(100deg, ${props => props.fill1}, ${props => props.fill2});
    border-radius: 3px;
    height: 28px;
    white-space: nowrap;
  `,
};
