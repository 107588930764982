import { css } from '@emotion/react';
import { Menu, Select, Tooltip } from '@mantine/core';
import { memo } from 'react';
import { BiChart } from 'react-icons/bi';
import { useSnapshot } from 'valtio';
import { store } from '~/pages/heineken_template/_private/store';
import { fill_vertical_all_center } from '../AppLayout/FlexGridCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
//一般下拉式選單
const SelectSwitch = memo(function SelectSwitch(props) {
    const data = props.data;
    //此元件開放給 web_XXXXX_pro使用
    const pro = fr_me.toTemplate.permissions.hasPermission(props.permission).useCheck();
    return (<Tooltip label='專業版用戶限定' disabled={pro} withArrow={false}>
        <div>
          <Select css={css `
              width: 120px;
              .mantine-58jpum {
                height: 40px;
              }
            `} disabled={!pro} placeholder='模板選擇' data={data} defaultValue={data[0].value} onChange={(value) => {
            store.charting.widgetOptions.client_id = value;
            store.charting.destroy();
            store.charting.create();
        }}/>
        </div>
      </Tooltip>);
});
//小圖表圖示下拉選單
const IconSwitch = memo(function IconSwitch(props) {
    const theme = useThemeStore(t => t.theme);
    const data = props.data;
    const clientId = useSnapshot(store.charting).widgetOptions.client_id;
    //此元件開放給 web_opkevin_pro使用
    const pro = fr_me.toTemplate.permissions.hasPermission(props.permission).useCheck();
    return (<Tooltip label='專業版用戶限定' disabled={pro} withArrow={false}>
      <Menu shadow='md' width={200} disabled={!pro}>
        <Menu.Target>
          <div css={css `
              ${fill_vertical_all_center};
              width: 32px;
              cursor: pointer;
            `}>
            <BiChart size={32}/>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>圖表樣板切換</Menu.Label>
          {data.map(s => {
            return (<Menu.Item className={theme} css={css `
                  &.dark {
                    border: 1px solid ${clientId === s.value ? '#dadada' : 'transparent'};
                  }
                  &.light {
                    border: 1px solid ${clientId === s.value ? '#454545' : 'transparent'};
                  }
                `} key={s.value} onClick={() => {
                    store.charting.widgetOptions.client_id = s.value;
                    store.charting.destroy();
                    store.charting.create();
                }}>
                {s.label}
              </Menu.Item>);
        })}
        </Menu.Dropdown>
      </Menu>
    </Tooltip>);
});
export default {
    IconSwitch: IconSwitch,
    SelectSwitch: SelectSwitch,
};
