import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, TextInput } from '@mantine/core';
import { memo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { apirc } from '~/configs/apirc';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
export const ProductKey = memo(function ProductKey() {
    const [ActivationCode, setActivationCode] = useState('');
    const [sendCodeState, sendCode] = useAsyncFn(async (code) => await apirc.me.api.postActivationCode.fetch(code), []);
    return (<styleds.PromoCodeContent>
      <styleds.PromoTitle>輸入啟用序號</styleds.PromoTitle>
      <styleds.PromoInput>
        <TextInput value={ActivationCode} placeholder='輸入啟用序號' variant='filled' onChange={event => {
            setActivationCode(event.currentTarget.value);
        }}/>
        <Button css={css `
            width: 80px;
          `} gradient={{ from: 'indigo', to: 'cyan' }} variant='gradient' onClick={() => {
            sendCode(ActivationCode);
        }}>
          {sendCodeState.loading ? '送出中' : '送出'}
        </Button>
      </styleds.PromoInput>

      <styleds.PromoState>
        {sendCodeState?.value && sendCodeState.value?.activationCode + '啟動權限 請重整畫面'}
        {sendCodeState?.error && '查無序號 請重新輸入'}
      </styleds.PromoState>
    </styleds.PromoCodeContent>);
});
const styleds = {
    PromoCodeContent: styled.div `
    grid-row: 7;
    ${fill_vertical_all_center};
    height: 108px;
  `,
    PromoTitle: styled.div `
    ${fill_vertical_all_center};
    height: 30px;
  `,
    PromoInput: styled.div `
    ${fill_horizontal_all_center};
    height: calc(100% - 60px);
  `,
    PromoState: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    width: 260px;
    color: #ca4b4b;
    border-radius: 5px;
    font-size: 14px;
  `,
};
