import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Slider } from '@mantine/core';
import { memo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const stockScoreSensitivityStore = proxy({
    value: 250,
});
export const StockScoreRankSlider = memo(function StockScoreRankSlider() {
    const state = useSnapshot(stockScoreSensitivityStore);
    return (<styleds.container>
      低
      <Slider css={css `
          width: 100%;
          padding: 0 16px;
        `} color='indigo' radius='xs' min={1} max={500} step={250} labelTransition='fade' size={6} label={null} value={state.value} onChange={value => {
            stockScoreSensitivityStore.value = value;
        }}/>
      高
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center}
    height: 40px;
    width: 100%;
    background-color: #252525;
    padding: 0px 8px;
    font-size: 14px;
  `,
};
