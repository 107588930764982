import { useMeStore } from '~/modules/SDK/me/useMeStore';
import dayAPI from '~/utils/dayAPI';
/** @param product trial = 試用中 premium = */
export const useDaddy960Trial = (product) => {
    const meUserState = useMeStore(state => state.meUserState);
    const hasLogin = meUserState !== null;
    const tutorialProduct = meUserState?.subscriptions?.find(s => s.agentName === 'daddy960' && s.productName === 'web_' + product);
    /** 會員目前狀況 trial(試用) premium(付費) */
    const productState = tutorialProduct?.status;
    /** 試用狀態會員 */
    const trialUser = productState === 'trial';
    /** 付費狀態會員 */
    const premiumUser = productState === 'premium';
    /** 透過註冊 /signup 會新增一個 `default` 商品，不會有 `product`商品 */
    const newUser = !trialUser && !premiumUser;
    const expire = dayAPI() > dayAPI(tutorialProduct?.expiredAt);
    //
    console.log({
        登入狀態: { 登入: hasLogin },
        權限狀態: { 狀態: productState },
        全部狀態: { 試用狀態: trialUser, 會員狀態: premiumUser, 新進用戶: newUser },
        試用資訊: {
            啟用時間: dayAPI(tutorialProduct?.startedAt).format('YYYY-MM-DD HH:mm'),
            到期時間: dayAPI(tutorialProduct?.expiredAt).format('YYYY-MM-DD HH:mm'),
            是否過期: expire,
        },
    });
    return {
        state: {
            /** 是否且已登入 */
            hasLogin: hasLogin,
            /** 是否新用戶 */
            newUser: newUser,
            /** subscriptions state */
            subscriptionsState: productState,
            /** 是否到期 */
            expire: expire,
        },
    };
};
/**
 * 顯示 @啟動試用按鈕 subscriptions.status === undefined product此時是`default`或整個`subscriptions是空的`
 *
 * 顯示 @已無試用權限 subscriptions.status === trial || premium && expire === true
 * product此時是`trial`或`premium`且使用期限已經到期
 */
