import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill, fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
const GoToBottomButton = memo(function GoToBottomButton() {
    const [showButton, setShowButton] = useState(true);
    const { isPc } = useMedia();
    const handleClick = () => {
        window.scrollTo({ top: 999, behavior: 'smooth' });
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollBottom = documentHeight - (scrollTop + windowHeight);
        if (scrollBottom > 50) {
            setShowButton(true);
        }
        else {
            setShowButton(false);
        }
    };
    return (<styleds.container bottom={isPc ? 4 : 6}>
      {showButton && <styleds.button onClick={handleClick}>關於我們</styleds.button>}
    </styleds.container>);
});
const classes = {
    Root: styled.div `
    ${fill}
  `,
};
const styleds = {
    container: styled.div `
    width: 88px;
    height: 30px;
    position: fixed;
    z-index: 2;
    right: 1%;
    bottom: ${props => props.bottom}%;
  `,
    button: styled.div `
    ${fill_horizontal_all_center};
    background-color: #56565677;
    color: #ffffff77;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14.8px;
    &:hover {
      color: #ffffff;
      background-color: #565656aa;
    }
  `,
};
export default {
    dispaly: GoToBottomButton,
};
