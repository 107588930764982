import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
/**
 * 用來建立「Charting 內建的指標」並使裝載指標的流程，與「客製自寫指標」的裝載方式一致
 *
 * @example
 *   //
 *   // 當我們寫了一個「加載成交量分佈圖」的指標
 *   export const VPVR = createIndicatorOfStudy(() => {
 *     const allStudies = store.charting.widget?.activeChart().getAllStudies()
 *
 *     // 防止指標被重複加入多個
 *     const found = find(allStudies, study => study.name === 'Volume Profile Visible Range')
 *
 *     if (!found) {
 *       store.charting.widget
 *         ?.activeChart()
 *         .createStudy('Volume Profile Visible Range', false, false, [
 *           'Number Of Rows',
 *           24,
 *           'Up/Down',
 *           0,
 *           0,
 *           70,
 *         ])
 *     }
 *   })
 *
 *   //
 *   // 在 templateStore 裡裝入上述指標
 *   // 然後 Charting4 就會呈現該指標囉
 *   store.charting.initStrategy({
 *     configs: [
 *       {
 *         displayName: '全部指標',
 *         indicators: [
 *           // 成交量分佈圖指標
 *           VPVR, // 裝入！！
 *
 *           // 其它正常客製指標
 *           bs0ForTVChart2,
 *           bs1ForTVChart2,
 *         ],
 *         interval: 5,
 *         symbol: 'TX-1',
 *         panesRatio: 37.5,
 *       },
 *     ],
 *   })
 */
export const createIndicatorOfStudy = (createFn) => {
    return createIndicator({
        id: Math.random().toString(36).slice(2),
        displayName: '建立 Charting 內建指標',
        enabledOn() {
            createFn();
            return false;
        },
        constructorScope: {
            init(context, inputCallback) {
                return;
            },
            main(context, inputCallback) {
                return;
            },
        },
        metainfo: {},
    });
};
