import { css } from '@emotion/react';
import { Button } from '@mantine/core';
import React, { memo, useState } from 'react';
import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
/** 記錄客戶按下試用按鈕行為 觸發後續'跳轉'與'試用申請中'的字眼 */
export const redirectStore = proxy({
    redirect: false,
});
export const Daddy960FreeTrialButton = memo(function Daddy960FreeTrialButton() {
    const [readyTrial, setReadyTrial] = useState(false);
    //點擊送出試用過3秒再重整網頁
    const handleClick = () => {
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    };
    return (<>
      {readyTrial === false && (<Button css={css `
            ${fill_horizontal_all_center}
            width: 260px;
            height: 36px;
            color: #333333;
            background-color: #ffc641;
          `} 
        //onClick={() => setReadyTrial(true)} 棄用再次確認
        onClick={() => {
                apirc.me.api.postFreeTrial.fetch();
                redirectStore.redirect = true;
                handleClick();
            }}>
          點擊按鈕開始7天試用
        </Button>)}
      {/* 棄用再次確認 */}
      {/* {readyTrial === true && (
          <Button
            css={css`
              ${fill_horizontal_all_center};
              width: 260px;
              height: 36px;
              color: #333333;
            `}
            onClick={() => {
              apirc.me.api.postFreeTrial.fetch()
              redirectStore.redirect = true
              handleClick()
            }}
            color='yellow'
          >
            再次點擊啟動試用
          </Button>
        )} */}
    </>);
});
